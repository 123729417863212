import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../Logo/Logo';
import styles from './Footer.module.css';
import { Link } from 'gatsby';

const Footer = () => (
  <div className={styles.footerContainer}>
    <div className={styles.footerBottom}>
      <div className={styles.bottomLinksContainer}>
        <div className={styles.bottomLinks}>
          <Link to={'/'}>Home</Link>
        </div>
        <div className={styles.bottomLinks}>|</div>
        <div className={styles.bottomLinks}>
          <a href={'https://xapi.ieee-saopen.org/sitemap/'}>Sitemap</a>
        </div>
        <div className={styles.bottomLinks}>|</div>
        <div className={styles.bottomLinks}>
          <a href={'mailto:opensource@ieee.org'}>Contact & Support</a>
        </div>
        <div className={styles.bottomLinks}>|</div>
        <div className={styles.bottomLinks}>
          <Link to={'/legal'}>Legal</Link>
        </div>
        <div className={styles.bottomLinks}>|</div>
        <div className={styles.bottomLinks}>
          <a href={'https://www.ieee.org/accessibility_statement.html'}>
            Accessibility
          </a>
        </div>
        <div className={styles.bottomLinks}>|</div>
        <div className={styles.bottomLinks}>
          <a href={'https://www.ieee.org/nondiscrimination'}>
            Nondiscrimination Policy
          </a>
        </div>
        <div className={styles.bottomLinks}>|</div>
        <div className={styles.bottomLinks}>
          <a href={'https://www.ieee.org/privacy'}>IEEE Privacy Policy</a>
        </div>
      </div>
      <div className={styles.copyright}>
          © 2022 XAPI Authors
	  © 2022 IEEE - Use of this website signifies your
        agreement to the{' '}
        <a href={'https://www.ieee.org/about/help/site-terms-conditions.html'}>
          IEEE Terms and Conditions
        </a>
        .
        <br />A not-for-profit organization, IEEE is the world's largest
        technical professional organization dedicated to advancing technology
        for the benefit of humanity.
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
