/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../Header/Header';
import SiteWrapper from '../SiteWrapper/SiteWrapper';
import Footer from '../Footer/Footer';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/*<script src="https://s3-us-west-2.amazonaws.com/ieeeshutpages/gdpr/settings.js"></script>*/}
        {/*<link*/}
        {/*  rel="stylesheet"*/}
        {/*  type="text/css"*/}
        {/*  href="https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css"*/}
        {/*/>*/}
        {/*<script src="https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js"></script>*/}
        {/*<script>*/}
        {/*  window.addEventListener("load", function()*/}
        {/*  {window.cookieconsent.initialise(json)});*/}
        {/*</script>*/}
      </Helmet>
      <SiteWrapper>
        <Header />
        <main>{children}</main>
        <Footer />
      </SiteWrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
