import React from 'react';
import styles from './Logo.module.css';
import { Link } from 'gatsby';

import LogoImage from '../../images/IEEE-SA_OPEN_BlackBackground.png';

const Logo = () => (
  <div className={styles.logoContainer}>
    <Link to={'/'}>
      <img className={styles.logoImage} src={LogoImage} alt={'IEEE SA Open'} />
    </Link>
  </div>
);

export default Logo;
