import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../Logo/Logo';
import styles from './Header.module.css';

import IEEE_MB_WHITE from '../../images/ieee-mb-white.png';

const Header = () => (
  <div className={styles.headerContainer}>
    <div className={styles.headerTop}>
      <div className={styles.topLinkContainer}>
        <div className={styles.topLink}>
          <a href={'https://www.ieee.org'}>IEEE.org</a>
        </div>
        <div className={styles.topLink}>|</div>
        <div className={styles.topLink}>
          <a href={'https://ieeexplore.ieee.org'}>IEEE Xplore Digital Library</a>
        </div>
        <div className={styles.topLink}>|</div>
        <div className={styles.topLink}>
          <a href={'https://standards.ieee.org'}>IEEE Standards</a>
        </div>
        <div className={styles.topLink}>|</div>
        <div className={styles.topLink}>
          <a href={'https://spectrum.ieee.org'}>IEEE Spectrum</a>
        </div>
        <div className={styles.topLink}>|</div>
        <div className={styles.topLink}>
          <a href={'https://www.ieee.org/sitemap'}>More sites</a>
        </div>
      </div>
      <div className={styles.topLogoContainer}>
        <img src={IEEE_MB_WHITE} alt={'IEEE'} />
      </div>
    </div>
    <div className={styles.headerBottom}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <div className={styles.bottomLinkContainer}>
        <div className={styles.link}>
          <Link to={'/standard'}>Draft xAPI Base Standard</Link>
        </div>
        <div className={styles.link}>
          <Link to={'/getting-started'}>GETTING STARTED</Link>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
