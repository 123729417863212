import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './SiteWrapper.module.css';
import PropTypes from 'prop-types';
import '../../styles/default.css';
import '../../styles/styleguide.css';

const SiteWrapper = ({ children }) => {
  return (
    <div className={styles.siteWrapper}>
      <Helmet>
        <meta name="viewport" content="width=1180" />
        <script src="https://s3-us-west-2.amazonaws.com/ieeeshutpages/gdpr/settings.js" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css"
        />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js" />
      </Helmet>
      {children}
    </div>
  );
};

export default SiteWrapper;

SiteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
